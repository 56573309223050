<template>
  <terms-container/>
</template>

<script>
  import TermsContainer from '@/entities/terms/terms.container';

  export default {
    name: 'TermsView',
    components: { TermsContainer },
    props: {},

    data() {
      return {};
    },

    computed: {},

    methods: {},

  };
</script>

<style lang="scss" scoped>

</style>
