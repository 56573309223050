<template>
  <div class="sp-terms-container">
    <div class="sp-terms-container-tabs">
      <div class="sp-terms-container-tabs-title">
        Store Policies
      </div>
      <div
        v-for="item in routes"
        :key="item.label"
        class="sp-terms-container-tabs-item"
        :class="{
          'sp-terms-container-tabs-item': true,
          'sp-terms-container-tabs-item-active': item.name === activeRoute,
        }"
      >
        <router-link :to="{ name: item.name }">
          {{ item.label }}
        </router-link>
      </div>
    </div>
    <div class="sp-terms-container-content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TermsContainer',
    props: {},

    data() {
      return {
        routes: [
          {
            name: 'privacy-policy',
            label: 'Privacy Policy',
          },
          {
            name: 'terms-and-conditions',
            label: 'Terms and Conditions',
          },
          {
            name: 'cookies-policy',
            label: 'Cookies Policy',
          },
          {
            name: 'third-party-cookies',
            label: 'Third Party Cookies',
          },
        ],
      };
    },

    computed: {
      activeRoute() {
        return this.$route.name;
      },
    },

    methods: {},

  };
</script>

<style lang="scss">
  .sp-terms-container {
    max-width: 1200px;
    margin: 30px auto 0 auto;
    width: 100%;

    @media (max-width: 1200px) {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    &-content {
      ol, ul {
        font-size: 15px;
      }
      p {
        font-size: 15px;
      }
      h1 {
        font-size: 30px;
      }
      h2 {
        font-size: 20px;
      }
      h4, h3 {
        font-size: 15px;
      }

      a {
        color: #4B89E5 !important;
      }

      td {
        font-size: 14px;
      }

      table {
        margin-bottom: 15px;
      }

    }
    &-tabs {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      font-size: 14px;
      &-title {
        font-weight: 500;
        margin-right: 1rem;
      }

      &-item {
        a {
          color: #4B89E5;
        }
        padding: 0.5rem 0.75rem;
        &-active {
          a {
            color: #343434;
          }
          border-radius: 100px;
          border: 1px solid #E7E7E7;
        }
      }
      & > {
        margin-right: 1rem;
      }
    }
  }
</style>
